import NewOrderFormCard from "components/atoms/NewOrders/NewOrderFormCard";
import { FormikContextType } from "formik";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { ISupplierFinancialAbilityValues } from "types";
import FormikInput from "../FormikInput";
import FormikToggle from "../FormikToggle";
import cn from "classnames";
import SupplierFormTitle from "components/atoms/Supplier/SupplierFormTitle";

interface ISupplierFinanceFormProps {
  formik: FormikContextType<ISupplierFinancialAbilityValues>;
  isStepView?: boolean;
}

const SupplierFinanceForm: React.FC<ISupplierFinanceFormProps> = ({
  formik,
  isStepView,
}) => {
  const { t } = useTranslation("common");

  const { isFinancingAbility } = formik.values;

  return (
    <div>
      {isStepView && (
        <SupplierFormTitle
          title={t("finances_form_title")}
          subtitle={t("customer_credits")}
        />
      )}
      <NewOrderFormCard title={t("financial_ability")}>
        <div
          className={cn(
            "flex justify-between items-center",
            isFinancingAbility && "mb-4"
          )}
        >
          <div>
            <p className="label-100 font-semibold mb-0.5">
              {t("financial_ability")}
            </p>
            <p className="body-100-subtle">{t("customer_credits")}</p>
          </div>
          <FormikToggle
            name="isFinancingAbility"
            label={isFinancingAbility ? "On" : "Off"}
          />
        </div>
        {isFinancingAbility && (
          <FormikInput
            name="financingAbilityDescription"
            containerClassName="pb-0"
            label={t("financial_ability_desc")}
            placeholder={t("enter_financial_ability_desc")}
            boldLabel
          />
        )}
      </NewOrderFormCard>
    </div>
  );
};

export default SupplierFinanceForm;
