import SupplierBasicForm from "components/molecules/Supplier/SupplierBasicForm";
import SupplierGalleryForm from "components/molecules/Supplier/SupplierGalleryForm";
import SupplierPaymentForm from "components/molecules/Supplier/SupplierPaymentForm";
import { Supplier } from "models/Supplier";
import { Translate } from "next-translate";
import * as yup from "yup";
import {
  businessModelOptions,
  currencyOptions,
  SUPPLIER_FORM_TABS,
} from "./constants";
import { countryOptions } from "./countriesSelectOptions";
import capitalize from "lodash/capitalize";
import {
  ISupplierBasicFormValues,
  ISupplierPaymentValues,
  ISupplierProductionValues,
  KeywordType,
} from "types";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import SupplierProductionForm from "components/molecules/Supplier/SupplierProductionForm";
import SupplierFinanceForm from "components/molecules/Supplier/SupplierFinanceForm";

export const transformKeywordToOptions = (keywordSet?: KeywordType[]): any => {
  if (keywordSet && keywordSet.length) {
    const grouped = groupBy(keywordSet, "style");

    return Object.entries(grouped).reduce((acc, [key, values]: any) => {
      if (key === "material") {
        return {
          ...acc,
          [key]: values.map((el: KeywordType) => el.value).join(", "),
        };
      }
      return {
        ...acc,
        [key]: values.map((el: KeywordType) => ({
          name: el.value,
          value: el.origin,
        })),
      };
    }, {});
  }

  return {
    gender: [],
    // catgroup: [],
    // basecat: [],
    // subcat: [],
    country: [],
    material: "",
  };
};

const getBasicFormValidationSchema = (t: Translate) =>
  yup.object({
    name: yup.string().required(t("field_required", { field: t("name") })),
    location: yup
      .object()
      .required(t("field_required", { field: t("location") })),
    service: yup.array().min(1, t("field_required", { field: t("service") })),
    description: yup
      .string()
      .required(t("field_required", { field: t("description") })),
    businessModel: yup
      .object()
      .required(t("field_required", { field: t("business_model") })),
    monthlyOutput: yup
      .number()
      .integer(t("must_be_integer", { field: t("monthly_output") }))
      .required(t("field_required", { field: t("monthly_output") })),
    grossAnnualSalesCurrency: yup
      .object({
        name: yup.string(),
        value: yup.string(),
      })
      .required(t("field_required", t("gross_annual_sales"))),
    grossAnnualSales: yup
      .number()
      .required(t("field_required", { field: t("gross_annual_sales") })),
    productionLeadTime: yup
      .number()
      .integer(t("must_be_integer", { field: t("production_lead_time") }))
      .required(t("field_required", { field: t("production_lead_time") })),
    moq: yup
      .number()
      .integer(t("must_be_integer", { field: t("MOQ") }))
      .required(t("field_required", { field: "MOQ" })),
    shippingMethod: yup
      .array()
      .of(
        yup.object({
          name: yup.string(),
          value: yup.string(),
        })
      )
      .min(1, t("field_required", { field: t("shipping_method") })),
    rdStuffCount: yup
      .number()
      .integer(t("must_be_integer", { field: t("rd_staff") }))
      .required(t("field_required", { field: t("rd_staff") })),
    qaStuffCount: yup
      .number()
      .integer(t("must_be_integer", { field: t("qa_staff") }))
      .required(t("field_required", { field: t("qa_staff") })),
    employeeCount: yup
      .number()
      .integer(t("must_be_integer", { field: t("employee_count") }))
      .required(t("field_required", { field: t("employee_count") })),
  });

const getPaymentValidation = (t: Translate) =>
  yup.object({
    paymentTerms: yup
      .array()
      .of(
        yup.object({
          title: yup.string(),
          payout: yup
            .number()
            .nullable()
            .moreThan(-1, t("less_error", { date: 0 })),
        })
      )
      .test("sum", "Sum of payouts must be equal to 100", (arr) => {
        const sum = arr?.reduce((acc, el) => (acc += el.payout || 0), 0) || 0;
        return sum === 100;
      }),
  });

const getGalleryValidation = (t: Translate) =>
  yup.object({
    productGallery: yup
      .array()
      .of(
        yup.object({
          url: yup.string(),
          mimetype: yup.string(),
        })
      )
      .min(1, t("field_required", { field: t("product_gallery") })),
    factoryGallery: yup
      .array()
      .of(
        yup.object({
          url: yup.string(),
          mimetype: yup.string(),
        })
      )
      .min(1, t("field_required", { field: t("factory_gallery") })),
    certificationFiles: yup.array().of(
      yup.object({
        url: yup.string(),
        mimetype: yup.string(),
      })
    ),
  });

const getProductionValidation = (t: Translate) =>
  yup.object({
    gender: yup
      .array()
      .of(
        yup.object({
          name: yup.string(),
          value: yup.string(),
        })
      )
      .min(1, t("field_required", { field: capitalize(t("simple_gender")) })),
    material: yup
      .string()
      .required(t("field_required", { field: capitalize(t("materials")) })),
    country: yup
      .array()
      .of(yup.object())
      .min(1, t("field_required", { field: t("production_countries") })),
    productionNotes: yup.string(),
    isPublicOrdersDisabled: yup.boolean(),
    workingHoursStart: yup
      .string()
      .nullable()
      .required(t("field_required", { field: t("start_time") })),
    workingHoursEnd: yup
      .string()
      .nullable()
      .required(t("field_required", { field: t("end_time") })),
    timeZone: yup
      .object()
      .nullable()
      .required(t("field_required", { field: t("timezone") })),
  });

const getFinancialAbilityValidation = () =>
  yup.object({
    isFinancingAbility: yup.boolean(),
    financingAbilityDescription: yup.string().nullable(),
  });

const transformBasicValues = (values: ISupplierBasicFormValues) => {
  const {
    location,
    service,
    businessModel,
    grossAnnualSalesCurrency,
    shippingMethod,
    grossAnnualSales,
    ...rest
  } = values;

  return {
    locations: [location.value],
    service: service?.map((el) => el.value),
    businessModel: businessModel?.value,
    grossAnnualSalesCurrency: grossAnnualSalesCurrency?.value,
    grossAnnualSales: grossAnnualSales
      ? grossAnnualSales * 100
      : grossAnnualSales,
    shippingMethod: shippingMethod?.map((el) => el.value),
    ...rest,
  };
};

const transformProductionValues = (values: ISupplierProductionValues) => {
  const { gender, material, country, isPublicOrdersDisabled, ...rest } = values;

  const keywordSet = material
    .split(",")
    .map((el) => ({ style: "material", value: el.trim(), origin: el.trim() }));

  keywordSet.push(
    ...gender.map((el) => ({
      style: "gender",
      value: el.name,
      origin: el.value,
    }))
  );

  keywordSet.push(
    ...country.map((el) => ({
      style: "country",
      value: el.value,
      origin: el.value,
    }))
  );

  return {
    keywordSet,
    portal: {
      isPublicOrdersDisabled,
    },
    ...rest,
  };
};

const transformPaymentValues = (values: ISupplierPaymentValues) => {
  return {
    paymentTerms: values.paymentTerms.map((el) => ({
      ...el,
      payout: +(el.payout || 0),
    })),
  };
};

const getForms = (t: Translate, supplier?: Supplier) => ({
  [SUPPLIER_FORM_TABS.BASIC]: {
    validationSchema: getBasicFormValidationSchema(t),
    initialValues: {
      name: supplier?.name || "",
      location: supplier
        ? countryOptions.find((el) => el.value === supplier.locations?.[0])!
        : countryOptions.find((el) => el.code === "US")!,
      service: supplier
        ? supplier.service?.map((el) => ({ name: el, value: el }))
        : [],
      description: supplier?.description || "",
      businessModel: supplier
        ? businessModelOptions.find(
            (el) => el.value === supplier.businessModel
          )!
        : undefined,
      monthlyOutput: supplier?.monthlyOutput || "",
      grossAnnualSalesCurrency: supplier
        ? currencyOptions.find(
            (el) => el.value === supplier.grossAnnualSalesCurrency
          )!
        : currencyOptions[0],
      grossAnnualSales: supplier?.grossAnnualSales
        ? supplier?.grossAnnualSales / 100
        : "",
      productionLeadTime: supplier?.productionLeadTime || "",
      moq: supplier?.moq || "",
      shippingMethod: supplier
        ? supplier.shippingMethod?.map((el) => ({ name: el, value: el }))
        : [],
      rdStuffCount: supplier?.rdStuffCount || "",
      qaStuffCount: supplier?.qaStuffCount || "",
      employeeCount: supplier?.employeeCount || "",
      certificationFiles: supplier?.certificationFiles || [],
    },
    Form: SupplierBasicForm,
    transformValues: transformBasicValues,
  },

  [SUPPLIER_FORM_TABS.PAYMENT]: {
    validationSchema: getPaymentValidation(t),
    initialValues: {
      paymentTerms: supplier?.paymentTerms?.length
        ? supplier?.paymentTerms
        : [
            { title: t("deposit") },
            { title: t("shipment") },
            { title: t("final") },
          ],
    },
    Form: SupplierPaymentForm,
    transformValues: transformPaymentValues,
  },

  [SUPPLIER_FORM_TABS.GALLERY]: {
    validationSchema: getGalleryValidation(t),
    initialValues: {
      productGallery: supplier?.productGallery || [],
      factoryGallery: supplier?.factoryGallery || [],
    },
    Form: SupplierGalleryForm,
  },

  [SUPPLIER_FORM_TABS.PRODUCTION]: {
    validationSchema: getProductionValidation(t),
    initialValues: {
      ...transformKeywordToOptions(supplier?.keywordSet?.keywordSet),
      workingHoursStart: supplier?.workingHoursStart,
      workingHoursEnd: supplier?.workingHoursEnd,
      timeZone: supplier?.timeZone,
      productionNotes: supplier?.productionNotes || "",
      isPublicOrdersDisabled: isNil(supplier?.portal?.isPublicOrdersDisabled)
        ? false
        : supplier?.portal?.isPublicOrdersDisabled,
    },
    Form: SupplierProductionForm,
    transformValues: transformProductionValues,
  },

  [SUPPLIER_FORM_TABS.FINANCE]: {
    validationSchema: getFinancialAbilityValidation(),
    initialValues: {
      isFinancingAbility: isNil(supplier?.isFinancingAbility)
        ? false
        : supplier?.isFinancingAbility,
      financingAbilityDescription: supplier?.financingAbilityDescription || "",
    },
    Form: SupplierFinanceForm,
  },
});

export const getSupplierForm = (
  form: string,
  t: Translate,
  supplier?: Supplier
) => getForms(t, supplier)[form];
