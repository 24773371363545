import React from "react";
import cn from "classnames";

interface ISupplierFormTitleProps {
  title: string;
  subtitle?: string;
  className?: string;
}

const SupplierFormTitle: React.FC<ISupplierFormTitleProps> = ({
  title,
  subtitle,
  className,
}) => (
  <div className={cn("pb-6", className)}>
    <h2 className={cn("headline-100", subtitle && "mb-2")}>{title}</h2>
    {!!subtitle && <p className="body-100">{subtitle}</p>}
  </div>
);

export default SupplierFormTitle;
