import React from "react";
import cn from "classnames";
import { SUPPLIER_FORM_TABS } from "utils/constants";

interface ISupplierFormSelectionProps {
  selected: keyof typeof SUPPLIER_FORM_TABS;
  onSelect: (val: keyof typeof SUPPLIER_FORM_TABS) => void;
  isStepView?: boolean;
  submitedFormIndexes?: number[];
}

const SupplierFormSelection: React.FC<ISupplierFormSelectionProps> = ({
  selected,
  onSelect,
  isStepView,
  submitedFormIndexes,
}) => {
  return (
    <div className="p-4 rounded-2xl border border-border-normal">
      {Object.values(SUPPLIER_FORM_TABS).map((val, index) => {
        return (
          <button
            disabled={
              isStepView
                ? selected !== val && !submitedFormIndexes?.includes(index)
                : false
            }
            onClick={() => onSelect(val as keyof typeof SUPPLIER_FORM_TABS)}
            key={val + index.toString()}
            className={cn(
              "px-2 py-2.5 rounded-md w-full body-100 text-left disabled:text-gray-40",
              selected === val && "bg-gray-20 font-semibold"
            )}
          >
            {val}
          </button>
        );
      })}
    </div>
  );
};

export default SupplierFormSelection;
