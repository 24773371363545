import CtaButton from "components/atoms/CtaButton";
import SupplierFormSelection from "components/molecules/Supplier/SupplierFormSelection";
import { FormikProvider, useFormik } from "formik";
import { Supplier } from "models/Supplier";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SUPPLIER_FORM_TABS } from "utils/constants";
import { getSupplierForm } from "utils/supplierFormUtils";

interface ISupplierFormViewProps {
  supplier?: Supplier;
  onSubmit: (values: Record<string, any>, form?: string) => void;
  submitText?: string;
  submitLoading?: boolean;
  onFormChange?: (val: string) => void;
  isStepView?: boolean;
  submitedFormIndexes?: number[];
}

const SupplierFormView: React.FC<ISupplierFormViewProps> = ({
  supplier,
  onSubmit,
  submitText,
  submitLoading,
  onFormChange,
  isStepView,
  submitedFormIndexes,
}) => {
  const { t } = useTranslation("common");
  const [selectedForm, setSelectedForm] = useState<string>(
    SUPPLIER_FORM_TABS.BASIC
  );

  const supplierForm = useMemo(() => {
    return getSupplierForm(selectedForm, t, supplier);
  }, [selectedForm, supplier]);

  const formik = useFormik({
    initialValues: supplierForm.initialValues,
    validationSchema: supplierForm.validationSchema,
    onSubmit: (values) => {
      const { transformValues } = supplierForm;
      onSubmit(
        !!transformValues ? transformValues(values) : values,
        selectedForm
      );
      if (isStepView) {
        const entries = Object.entries(SUPPLIER_FORM_TABS);

        const index = entries.findIndex(([_, val]) => val === selectedForm);

        if (index < entries.length - 1) {
          setSelectedForm(entries[index + 1][1]);
        }
      }
    },
    enableReinitialize: true,
  });

  const { Form } = supplierForm;

  useEffect(() => {
    if (!!onFormChange) {
      onFormChange(selectedForm);
    }
  }, [selectedForm, onFormChange]);

  const onBack = () => {
    if (isStepView) {
      const entries = Object.entries(SUPPLIER_FORM_TABS);

      const selectedIndex = entries.findIndex(
        ([_, val]) => val === selectedForm
      );

      setSelectedForm(entries[selectedIndex - 1][1]);
    }
  };

  return (
    <div className="grid xxs:grid-cols-1 md:!grid-cols-3 gap-4">
      <div className="h-fit">
        <SupplierFormSelection
          selected={selectedForm as keyof typeof SUPPLIER_FORM_TABS}
          onSelect={setSelectedForm}
          isStepView={isStepView}
          submitedFormIndexes={submitedFormIndexes}
        />
      </div>
      <div className="md:!col-span-2 xxs:col-span-1">
        <FormikProvider value={formik}>
          <Form isStepView={isStepView} formik={formik as any} />
        </FormikProvider>
      </div>
      <div className="flex md:!col-span-3 xxs:col-span-1 gap-2 justify-end">
        {!!isStepView && selectedForm !== SUPPLIER_FORM_TABS.BASIC && (
          <CtaButton
            size="md"
            className="capitalize"
            variant="secondary"
            onClick={onBack}
          >
            {t("back")}
          </CtaButton>
        )}
        <CtaButton
          spinner={submitLoading}
          size="md"
          onClick={formik.submitForm}
        >
          {submitText || t("save")}
        </CtaButton>
      </div>
    </div>
  );
};

export default SupplierFormView;
