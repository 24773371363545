import NewOrderFormCard from "components/atoms/NewOrders/NewOrderFormCard";
import { FormikContextType } from "formik";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { ISupplierGalleryValues } from "types";
import FilesPicker from "../FilesPicker";
import isArray from "lodash/isArray";
import SupplierFormTitle from "components/atoms/Supplier/SupplierFormTitle";
import InfoTooltip from "components/atoms/InfoTooltip";
import SupplierGalleryItem from "./SupplierGalleryItem";

interface ISupplierGalleryFormProps {
  formik: FormikContextType<ISupplierGalleryValues>;
  isStepView?: boolean;
}

const SupplierGalleryForm: React.FC<ISupplierGalleryFormProps> = ({
  formik,
  isStepView,
}) => {
  const { t } = useTranslation("common");

  const { productGallery, factoryGallery, certificationFiles } = formik.values;

  const onFilesChange =
    (field: "productGallery" | "factoryGallery" | "certificationFiles") =>
    (
      data:
        | { data: string; mimetype: string }
        | Array<{ data: string; mimetype: string }>
    ) => {
      let value = formik.values[field] || [];
      if (isArray(data)) {
        value = value.concat(
          data.map((el) => ({
            url: el.data,
            mimetype: el.mimetype,
          }))
        );
      } else {
        value.push({
          mimetype: data.mimetype,
          url: data.data,
        });
      }

      formik.setFieldValue(field, value);
    };

  const onDeleteImage =
    (field: "productGallery" | "factoryGallery" | "certificationFiles") =>
    (url: string) => {
      formik.setFieldValue(
        field,
        formik.values[field]?.filter((el) => el.url !== url)
      );
    };

  return (
    <div>
      {!!isStepView && (
        <SupplierFormTitle
          title={t("gallery_form_title")}
          subtitle={t("gallery_form_desc")}
        />
      )}

      <NewOrderFormCard title={t("product_gallery")}>
        <div className="flex flex-wrap mb-10 gap-2">
          {productGallery?.map((el, index) => (
            <SupplierGalleryItem
              onDelete={onDeleteImage("productGallery")}
              item={el}
              key={el.url + index.toString()}
            />
          ))}
          <FilesPicker
            accept={{ "image/*": [] }}
            onFilesChange={onFilesChange("productGallery")}
            name="productGallery"
            addButtonText={t("add_image")}
            hideMediaLibOption
            hideMoodboardOption
            multiple
            zoneClassName="h-[112px] p-6"
          />
        </div>
        <div className="mb-10">
          <div className="flex items-center mb-4">
            <p className="headline-300 font-semibold">
              {t("factory_certification")}
            </p>
            <InfoTooltip className="ml-2" dataTip={t("certification_tip")} />
            <span className="body-100-subtle text-gray-50 pl-1">
              {`- ${t("optional")}`}
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            {certificationFiles?.map((el, index) => (
              <SupplierGalleryItem
                onDelete={onDeleteImage("certificationFiles")}
                item={el}
                key={el.url + index.toString()}
              />
            ))}
            <FilesPicker
              accept={{ "image/*": [], "application/pdf": [".pdf"] }}
              onFilesChange={onFilesChange("certificationFiles")}
              name="certificationFiles"
              addButtonText={t("add_files")}
              multiple
              hideMediaLibOption
              hideMoodboardOption
              zoneClassName="h-[112px] w-[180px] p-6"
            />
          </div>
        </div>
        <div>
          <p className="mb-4 headline-300 font-semibold">
            {t("factory_gallery")}
          </p>
          <div className="flex flex-wrap gap-2">
            {factoryGallery?.map((el, index) => (
              <SupplierGalleryItem
                onDelete={onDeleteImage("factoryGallery")}
                item={el}
                key={el.url + index.toString()}
              />
            ))}
            <FilesPicker
              accept={{ "image/*": [] }}
              onFilesChange={onFilesChange("factoryGallery")}
              name="factoryGallery"
              addButtonText={t("add_image")}
              multiple
              hideMediaLibOption
              hideMoodboardOption
              zoneClassName="h-[112px] p-6"
            />
          </div>
        </div>
      </NewOrderFormCard>
    </div>
  );
};

export default SupplierGalleryForm;
