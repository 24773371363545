import NewOrderFormCard from "components/atoms/NewOrders/NewOrderFormCard";
import { FieldArray, FormikContextType } from "formik";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { IPaymentTerm } from "types";
import FormikPriceInput from "../FormikPriceInput";
import isString from "lodash/isString";
import SupplierFormTitle from "components/atoms/Supplier/SupplierFormTitle";

interface ISupplierPaymentFormProps {
  formik: FormikContextType<{ paymentTerms: IPaymentTerm[] }>;
  isStepView?: boolean;
}

const SupplierPaymentForm: React.FC<ISupplierPaymentFormProps> = ({
  formik,
  isStepView,
}) => {
  const { t } = useTranslation("common");

  const { paymentTerms } = formik.values;

  return (
    <div>
      {!!isStepView && (
        <SupplierFormTitle
          title={t("payment_form_title")}
          subtitle={t("payment_form_desc")}
        />
      )}
      <NewOrderFormCard
        error={
          (isString(formik.errors.paymentTerms) &&
            formik.errors.paymentTerms) as string
        }
        title={t("setup_payment_plan")}
      >
        <FieldArray
          name="paymentTerms"
          render={() => (
            <ul>
              {paymentTerms?.map((el, index) => (
                <li
                  key={el.title + index.toString()}
                  className="border-b py-4 border-border-normal last:border-none flex items-center justify-between"
                >
                  <p className="body-100">{el.title}</p>
                  <FormikPriceInput
                    name={`paymentTerms.${index}.payout`}
                    suffix="%"
                    prefix=""
                    placeholder="0%"
                    allowDecimals={false}
                    className="w-[80px]"
                    lowerError
                    decimalScale={0}
                    decimalsLimit={0}
                    max={100}
                    min={0}
                  />
                </li>
              ))}
            </ul>
          )}
        />
      </NewOrderFormCard>
    </div>
  );
};

export default SupplierPaymentForm;
