import Select, { ISelectProps } from "components/atoms/Select";
import { useField } from "formik";
import React from "react";
import { ISelectValue } from "types";
import timezones from "../../timezones.json";
import { FormikSelectProps } from "./FormikSelect";
import isEqual from "lodash/isEqual";

const FormikTimezoneSelect: React.FC<
  FormikSelectProps & Omit<ISelectProps, "options" | "value">
> = (props) => {
  const [field, meta] = useField(props as FormikSelectProps);

  const timezoneOptions = timezones.map((el) => ({ name: el.text, value: el }));

  return (
    <div className={props.containerClassName}>
      <Select
        {...field}
        {...props}
        value={
          field.value
            ? timezoneOptions.find((el) =>
                isEqual(el.value.text, field.value.text)
              )
            : field.value
        }
        options={timezoneOptions}
        errorMassage={meta.touched && meta.error ? meta.error : ""}
      />
    </div>
  );
};

export default FormikTimezoneSelect;
