import ToggleSwitch, {
  IToggleSwitchProps,
} from "components/atoms/ToggleSwitch";
import { useField } from "formik";
import React from "react";

interface IFormikToggleProps
  extends Omit<IToggleSwitchProps, "checked" | "onChange"> {
  name: string;
  reverse?: boolean;
}

const FormikToggle: React.FC<IFormikToggleProps> = ({ reverse, ...props }) => {
  const [field, _, tools] = useField(props);

  const onChange = (val: boolean) => tools.setValue(reverse ? !val : val, true);

  return (
    <ToggleSwitch
      {...field}
      {...props}
      onChange={onChange}
      checked={reverse ? !field.value : field.value}
    />
  );
};

export default FormikToggle;
