import NewOrderFormCard from "components/atoms/NewOrders/NewOrderFormCard";
import { FormikContextType } from "formik";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { ISelectValue, ISupplierBasicFormValues } from "types";
import { countryOptions } from "utils/countriesSelectOptions";
import FormikSelect from "../FormikSelect";
import pick from "lodash/pick";
import {
  businessModelOptions,
  currencyOptions,
  serviceOptions,
  shippingMethodOptions,
  SUPPLIER_BASIC_FIELDS,
} from "utils/constants";
import cn from "classnames";
import FormikInput from "../FormikInput";
import InfoTooltip from "components/atoms/InfoTooltip";
import FormikPriceInput from "../FormikPriceInput";
import SupplierFormTitle from "components/atoms/Supplier/SupplierFormTitle";

const INPUT_CLASS = "pb-0";

interface ISupplierBasicFormProps {
  formik: FormikContextType<ISupplierBasicFormValues>;
  isStepView?: boolean;
}

const SupplierBasicForm: React.FC<ISupplierBasicFormProps> = ({
  formik,
  isStepView,
}) => {
  const { t } = useTranslation("common");

  const {
    location,
    service,
    businessModel,
    grossAnnualSalesCurrency,
    shippingMethod,
  } = pick(formik.values, SUPPLIER_BASIC_FIELDS);

  const onSelect = (field: string) => (val: ISelectValue | ISelectValue[]) =>
    formik.setFieldValue(field, val);

  const onDeleteSelectedService = (val: ISelectValue) => {
    formik.setFieldValue(
      "service",
      service?.filter((el) => el.name !== val.name)
    );
  };

  return (
    <div>
      {isStepView && (
        <SupplierFormTitle
          title={t("basic_form_title")}
          subtitle={t("basic_form_desc")}
        />
      )}
      <NewOrderFormCard title={t("basic_information")}>
        <div className="grid xxs:grid-cols-1 md:!grid-cols-2 gap-4">
          <FormikInput
            name="name"
            label={t("company_name")}
            boldLabel
            containerClassName="pb-0"
            placeholder={t("enter_company_name")}
            lowerError
          />
          <FormikSelect
            search
            boldLabel
            name="location"
            placeholder={t("select_location")}
            options={countryOptions}
            label={t("location")}
            value={location}
            onSelect={onSelect("location")}
            buttonClassName={"w-full h-10"}
            compareBy="value"
          />
          <div className="md:!col-span-2 xxs:col-span-1">
            <FormikSelect
              boldLabel
              name="service"
              valueAsTag
              placeholder={t("select_service")}
              multiple
              options={serviceOptions}
              containerClassName="mb-4"
              label={t("service")}
              value={service}
              onSelect={onSelect("service")}
              buttonClassName={cn(
                "w-full h-10 w-full",
                !!service?.length && "h-auto"
              )}
              compareBy="value"
              onDeleteSelected={onDeleteSelectedService}
              optionsContainerClassName="top-auto !mt-0"
            />
            <FormikInput
              boldLabel
              name="description"
              placeholder={t("product_expertise")}
              label={t("product_expertise")}
              labelTip={t("supplier_description_tip")}
              containerClassName={INPUT_CLASS}
              lowerError
            />
          </div>
          <FormikSelect
            boldLabel
            name="businessModel"
            label={t("business_model")}
            placeholder={t("select_business_model")}
            value={businessModel}
            options={businessModelOptions}
            onSelect={onSelect("businessModel")}
            buttonClassName="w-full"
          />
          <FormikInput
            boldLabel
            name="monthlyOutput"
            labelTip={t("monthly_output_tip")}
            placeholder={t("enter_monthly_output")}
            label={t("monthly_output")}
            type="number"
            containerClassName={INPUT_CLASS}
            lowerError
          />
          <div>
            <div className="flex items-center mb-1">
              <p className="label-100 font-semibold">
                {t("gross_annual_sales")}
              </p>
              <InfoTooltip
                dataTip={t("gross_annual_sales_tip")}
                className="ml-2"
              />
            </div>
            <div className="flex items-center">
              <FormikSelect
                labelClassName="headline-400 font-semibold mb-1"
                name="grossAnnualSalesCurrency"
                value={grossAnnualSalesCurrency || currencyOptions[0]}
                onSelect={onSelect("grossAnnualSalesCurrency")}
                options={currencyOptions}
                buttonClassName="max-h-10 mr-2"
                disabled={currencyOptions?.length === 1}
              />
              <FormikPriceInput
                name="grossAnnualSales"
                prefix=""
                placeholder="0.00"
                lowerError
                className="w-full"
                containerClassName="w-full"
              />
            </div>
          </div>
          <FormikInput
            boldLabel
            name="productionLeadTime"
            type="number"
            label={t("production_lead_time")}
            labelTip={t("production_lead_time_tip")}
            placeholder={t("enter_production_lead_time")}
            containerClassName={INPUT_CLASS}
            lowerError
          />
          <FormikInput
            boldLabel
            name="moq"
            type="number"
            placeholder={t("enter_moq")}
            labelTip={t("moq_tip")}
            label="MOQ"
            containerClassName={INPUT_CLASS}
            lowerError
          />
          <FormikSelect
            boldLabel
            options={shippingMethodOptions}
            value={shippingMethod}
            label={t("shipping_method")}
            placeholder={t("select_shipping_method")}
            buttonClassName="w-full h-10"
            valueAsTag
            multiple
            compareBy="value"
            name="shippingMethod"
            onSelect={onSelect("shippingMethod")}
          />
        </div>
        <div className="w-full h-[1px] bg-border-normal my-8" />
        <div className="grid md:!grid-cols-2 xxs:grid-cols-1 gap-4">
          <FormikInput
            boldLabel
            name="rdStuffCount"
            label={t("rd_staff")}
            placeholder={t("enter_staff_count")}
            type="number"
            containerClassName={INPUT_CLASS}
            lowerError
          />
          <FormikInput
            boldLabel
            name="qaStuffCount"
            label={t("qa_staff")}
            placeholder={t("enter_staff_count")}
            type="number"
            containerClassName={INPUT_CLASS}
            lowerError
          />
          <FormikInput
            boldLabel
            name="employeeCount"
            label={t("employee_count")}
            placeholder={t("enter_employee_count")}
            type="number"
            containerClassName={INPUT_CLASS}
            lowerError
          />
        </div>
      </NewOrderFormCard>
    </div>
  );
};

export default SupplierBasicForm;
