import React from "react";
import TimePicker, { TimePickerProps } from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import cn from "classnames";
import { useField } from "formik";

const timePickerProps = {
  disableClock: true,
  clearIcon: null,
};

interface IFormikTimePickerProps {
  label?: string;
  name: string;
  className?: string;
  errorClassName?: string;
}

const FormikTimePicker: React.FC<IFormikTimePickerProps & TimePickerProps> = ({
  errorClassName,
  ...props
}) => {
  const [field, meta] = useField(props as IFormikTimePickerProps);

  return (
    <div className={cn("flex flex-col relative flex-1", props.className)}>
      <label htmlFor={props.name} className="label-100 mr-2 whitespace-nowrap">
        {props.label}
      </label>
      <TimePicker
        className={cn(
          "w-full border rounded-md border-border-normal",
          meta.touched && meta.error && "border-border-error"
        )}
        format="HH:mm"
        {...field}
        {...props}
        {...timePickerProps}
      />
      {!!meta.touched && meta.error && (
        <div
          className={cn(
            "error-100 absolute -bottom-[17px] whitespace-nowrap",
            errorClassName
          )}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default FormikTimePicker;
