import NewOrderFormCard from "components/atoms/NewOrders/NewOrderFormCard";
import { FormikContextType } from "formik";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { ISelectValue, ISupplierProductionValues } from "types";
import { genderOptions } from "utils/constants";
import FormikSelect from "../FormikSelect";
import capitalize from "lodash/capitalize";
import FormikInput from "../FormikInput";
import { countryOptions } from "utils/countriesSelectOptions";
import FormikToggle from "../FormikToggle";
import FormikTimezoneSelect from "../FormikTimezonesSelect";
import FormikTimePicker from "../FormikTimePicker";
import { usePortal } from "contexts/PortalContext";
import cn from "classnames";
import SupplierFormTitle from "components/atoms/Supplier/SupplierFormTitle";

interface ISupplierProductionFormProps {
  formik: FormikContextType<ISupplierProductionValues>;
  isStepView?: boolean;
}

const SupplierProductionForm: React.FC<ISupplierProductionFormProps> = ({
  formik,
  isStepView,
}) => {
  const { t } = useTranslation("common");
  const { isPortal } = usePortal();

  const { gender, country, isPublicOrdersDisabled } = formik.values;

  const onSelect = (field: string) => (val: ISelectValue | ISelectValue[]) => {
    formik.setFieldValue(field, val);
  };

  const onSelectTimezone = (val: ISelectValue) => {
    formik.setFieldValue("timeZone", val.value);
  };

  const onTimeChange = (field: string) => (val: any) => {
    formik.setFieldValue(field, val);
  };

  const onDeleteCountry = (val: ISelectValue) => {
    formik.setFieldValue(
      "country",
      country?.filter((el) => el.name !== val.name)
    );
  };

  return (
    <div>
      {!!isStepView && (
        <SupplierFormTitle
          title={t("production_form_title")}
          subtitle={t("production_form_desc")}
        />
      )}
      <NewOrderFormCard title={t("product_production")}>
        <div className="grid grid-cols-2 gap-4">
          <div className="xxs:col-span-2 xs:col-span-1">
            <FormikSelect
              name="gender"
              multiple
              label={capitalize(t("simple_gender"))}
              onSelect={onSelect("gender")}
              buttonClassName="w-full h-10"
              options={genderOptions}
              value={gender}
              placeholder={t("select_gender")}
              compareBy="value"
              boldLabel
            />
          </div>
          <div className="col-span-2">
            <FormikInput
              name="material"
              placeholder={t("enter_materials")}
              boldLabel
              label={t("materials")}
            />
            <FormikSelect
              name="country"
              boldLabel
              search
              multiple
              compareBy={"value"}
              options={countryOptions}
              onSelect={onSelect("country")}
              buttonClassName={cn("w-full h-10", country?.length && "h-auto")}
              onDeleteSelected={onDeleteCountry}
              containerClassName="mb-4"
              value={country}
              valueAsTag
              label={t("production_countries")}
              placeholder={t("select_countries")}
            />
            <FormikInput
              name="productionNotes"
              boldLabel
              optional
              label={t("production_notes")}
              placeholder={t("enter_production_notes")}
            />
            {isPortal && (
              <div className="flex justify-between items-center">
                <div>
                  <p className="label-100 font-semibold mb-0.5">
                    {t("marketplace_order")}
                  </p>
                  <p className="body-100-subtle">
                    {t("marketplace_order_desc")}
                  </p>
                </div>
                <FormikToggle
                  name="isPublicOrdersDisabled"
                  label={isPublicOrdersDisabled ? "Off" : "On"}
                  reverse
                />
              </div>
            )}
            <FormikTimezoneSelect
              containerClassName={cn(isPortal && "mt-12")}
              buttonClassName="w-full h-10"
              name="timeZone"
              label={t("timezone")}
              boldLabel
              onSelect={onSelectTimezone}
              placeholder={t("select_timezone")}
            />
          </div>
          <div className="col-span-2">
            <p className="mb-1 label-100 w-fit font-semibold">
              {t("working_hours")}
            </p>
            <div className="flex gap-4">
              <FormikTimePicker
                name="workingHoursStart"
                onChange={onTimeChange("workingHoursStart")}
              />
              <FormikTimePicker
                onChange={onTimeChange("workingHoursEnd")}
                name="workingHoursEnd"
              />
            </div>
          </div>
        </div>
      </NewOrderFormCard>
    </div>
  );
};

export default SupplierProductionForm;
